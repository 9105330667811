/**
 * Created by tmedrano on 04/12/2015.
 */
  angular
    .module('annexaApp')
    .directive('icon', function() {
		var directive = {
			restrict: 'E',
			link: function(scope, element, attrs){

				var supportedstyles=["AWESOME","awesome","material","MATERIALICONS"];//"glyphicon","GLYPHICON"];
				var iconname="check";
				var iconstyle="awesome";
				var iconclasses="";

				if(!(typeof  attrs.name === "undefined") && attrs.name!="") iconname=attrs.name;

				if(!(typeof  attrs.family === "undefined")) iconstyle=attrs.family;
				if(!$.inArray(iconstyle,supportedstyles)) iconstyle=supportedstyles[0];

				if(!(typeof  attrs.class === "undefined")) iconclasses=attrs.class;

				var ret="";

				if(iconstyle=='awesome'||iconstyle=='AWESOME') ret="<i class='fa fa-"+iconname+" "+iconclasses+"'></i>";
				if(iconstyle=='material'|| iconstyle=='MATERIALICONS') ret="<span class='material-icons "+iconclasses+"'>"+iconname+"</span>";
				//if(iconstyle=='glyphicon'|| iconstyle=='GLYPHICON') ret="<span class='glyphicon glyphicon-"+iconname+" "+iconclasses+"'></span>";
				element.replaceWith(ret);

				//return ret;

			}
		};
		return directive;
	});


