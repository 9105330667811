/**
 * Created by tmedrano on 04/12/2015.
 */
    'use strict';
    angular
        .module('annexaApp')
        .controller('signController', ['$rootScope','$scope','$log','$interval','$compile','$state','apiSign','DccumentsFactory','SignService','annexatranslateFilter','$translate', 'DTOptionsBuilder', 'DTColumnBuilder', '$filter', '$stateParams', 'HeaderService', 'ModalConfigConflict', 'ModalSignConflict', 'LocalDataFactory', 'LoginService', 'TableFilter', 'HelperService','RestService','Language', 'SignModals', 'AnnexaFormlyFactory', '$timeout', 'AnnexaPermissionsFactory', 'TableFilterFactory', 'RouteStateFactory','GlobalDataFactory','$http','$location','$window','$localStorage','DialogsFactory','ModalService', function ($rootScope,$scope,$log,$interval,$compile,$state,apiSign,DccumentsFactory,SignService,annexatranslateFilter,$translate,DTOptionsBuilder, DTColumnBuilder,$filter, $stateParams, HeaderService, ModalConfigConflict, ModalSignConflict, LocalDataFactory, LoginService, TableFilter,HelperService,RestService,Language, SignModals, AnnexaFormlyFactory, $timeout, AnnexaPermissionsFactory, TableFilterFactory, RouteStateFactory, GlobalDataFactory,$http,$location,$window,$localStorage,DialogsFactory,ModalService) {
        	$scope.lstSignActionTypeSelected = angular.fromJson($stateParams.lstSignActionTypeSelected);
            $scope.appletCertificates = [];
            $scope.appletReturnCertsExecuted = true;
            if($scope.isIE()){
                $scope.appletReturnCertsExecuted = false;
            }
            $scope.docsToSign = [];

            /********* INICI REFACTORITZACIÓ ************/
            $scope.languageColumn = Language.getActiveColumn();
            $scope.filterUrgent = '';
            if($stateParams.typeDash &&  
            		$stateParams.typeDash != 'urgent' && 
            		$stateParams.typeDash != 'isCloudSignUrlOK' && 
            		$stateParams.typeDash != 'isCloudSignUrlError'){
                $scope.parameter = $stateParams.typeDash;
            }else if($stateParams.typeDash == 'urgent'){
                $scope.filterUrgent = 'true';
                RouteStateFactory.addRouteState($state.current, $scope.filterUrgent, 'ColumnsFilter');
                $scope.parameter = '';

            }
            var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
            if(routeStateColumnsFilter) {
                $scope.filterUrgent = routeStateColumnsFilter.state;
            }

            LocalDataFactory.getAllData(['signstatestypes']).then(function(data) {
                $scope.signstatestypes = HelperService.getArray(data,'name', 'signstatestypes');
            }).catch(function(error){});

            $scope.tfilter = [
                { id: 'signactiontype', type: 'select', order: 0, label: 'global.literals.actions', dataType: 'DATABASE', data: 'SignActionType', addAll:true, nameProperty:$scope.languageColumn},
                { id: 'documenttype', type: 'select', order: 1, label: 'global.literals.docType', dataType: 'DATABASE', data: 'DocumentType', addAll:true, nameProperty:$scope.languageColumn},
                { id: 'title', type: 'text', order: 2, label: 'global.literals.title'},
                { id: 'observations', type: 'text', order: 3, label: 'global.literals.observations'},
                { id: 'dates', type: 'dateRange', order: 4, label: 'global.literals.actionDo', callAux: true},
                { id: 'user', type: 'text', order: 6, label: 'global.sign.pending.signers', callAux: true}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            if($scope.parameter){
                var filterSignActionType = $linq($scope.tfilter).where("x => x.id == 'signactiontype'").toArray();
                if(filterSignActionType && filterSignActionType.length > 0){
                    filterSignActionType[0].preFilter = $scope.parameter;
                }
            }
            $scope.tfilterFinish = [
                { id: 'signactiontype', type: 'select', order: 0, label: 'global.literals.actions', dataType: 'DATABASE', data: 'SignActionType', addAll:true, nameProperty:$scope.languageColumn},
                { id: 'documenttype', type: 'select', order: 1, label: 'global.literals.docType', dataType: 'DATABASE', data: 'DocumentType', addAll:true, nameProperty:$scope.languageColumn},
                { id: 'title', type: 'text', order: 2, label: 'global.literals.title'},
                { id: 'observations', type: 'text', order: 3, label: 'global.literals.observations'},
                { id: 'dates', type: 'dateRange', order: 4, label: 'global.literals.actionDo', callAux: true},
                { id: 'user', type: 'text', order: 6, label: 'global.sign.pending.signers', callAux: true}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilterFinish = routeState.state;
            }
            var divDocument = function(data, type, signing, meta){
                var html = new annexaDiv(' btn-group text-right min-adjust-180 btn-flexgroup');

                if(!signing.actionExecutionDate) {
                    if (signing.signActionType.code == apiSign.VALIDATE_CODE) {
                        html.htmlObj.addChildren(
                            new annexaModalButton(
                                'btn-success adjust-101 ',
                                '#alert-sign-modal',
                                'marcarAccioAlert(\'validar\',' + signing.id + ',' + signing.signProcess.document.id + ')',
                                '',
                                $filter('translate')('global.sign.literals.validate'),
                                'fa-check fa'
                            )
                        );
                    }
                    if (signing.signActionType.code == apiSign.SIGNATURE_CODE || signing.signActionType.code == apiSign.SIGNATURE_RECOGNIZED_CODE || signing.signActionType.code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE || signing.signActionType.code == apiSign.SIGNATURE_BIO_SIGN_CODE) {
                        html.htmlObj.addChildren(
                            new annexaButton(
                                'grey-500 text-white adjust-101 ',
                                'addToQueue(' + signing.id + ')',
                                '',
                                $filter('translate')('global.sign.pending.add_to_sign')
                            )
                        );
                    }
                    html.htmlObj.addChildren(
                        new annexaModalButton(
                            'btn-danger',
                            '#alert-sign-modal',
                            'marcarAccioAlert(\'reject\',' + signing.id + ',' + signing.signProcess.document.id + ')',
                            'sr-only',
                            $filter('translate')('global.literals.reject'),
                            'fa-close',
                            $filter('translate')('global.literals.reject')
                        )
                    );
                } else {
                    html.htmlObj.addChildren(
                        new annexaButton(
                            'grey-500 text-white oneButton',
                            'removeFromQueue('+signing.id+')',
                            '',
                            $filter('translate')('global.sign.pending.undo'),
                            'fa-reply')
                    );
                }

                return html.htmlObj.getHtml();
            }

            var divSignants = function(data, type, signing, meta){
                var html = new annexaDiv();
                var nameSendToSign = HelperService.getAllName(signing.signProcess.userSendedToSign);
                if(signing.userMustSign.identifier != $rootScope.LoggedUser.identifier) {
                    var nameMustSign = HelperService.getAllName(signing.userMustSign);
                    html.htmlObj.addChildren(
                      new annexaHref(
                          'text-primary tresPuntets tresPuntets50',
                          'annexa.profile({idUser: \''+signing.signProcess.userSendedToSign.identifier+'\'})',
                          nameSendToSign, undefined, nameSendToSign
                      )
                    );
                    html.htmlObj.addChildren(
                        new annexaSpan('>','text-muted')
                    );
                    html.htmlObj.addChildren(
                        new annexaHref(
                            'text-primary tresPuntets tresPuntets50',
                            'annexa.profile({idUser: \''+ signing.userMustSign.identifier+'\'})',
                            nameMustSign, undefined, nameMustSign
                        )
                    );
                }else{
                    html.htmlObj.addChildren(
                        new annexaHref(
                            'text-info linkNoStyle tresPuntets',
                            'annexa.profile({idUser: \''+signing.signProcess.userSendedToSign.identifier+'\'})',
                            nameSendToSign, undefined, nameSendToSign
                        )
                    );
                }
                var div = new annexaDiv();
                var html2 = new annexaSpan('');
                html2.htmlObj.addAttribute('translate', 'global.sign.pending.start');
                var html3 = new annexaSpan(': <span class="text-whitespace-nowrap">'+$filter('date')(new Date(signing.startDate), 'dd/MM/yyyy HH:mm')+'</span>');
                div.htmlObj.addChildren(html2);
                div.htmlObj.addChildren(html3);
                var a = new annexaModalHref(
                    'text-primary',
                    '#signCircuit',
                    'getCircuit('+signing.id+')',
                    undefined,
                    $filter('translate')('global.sign.literals.seeProcess')
                );

                return html.htmlObj.getHtml() + div.htmlObj.getHtml() + a.htmlObj.getHtml();
            }

            var divSignantsFinish = function(data, type, signing, meta){
                var html = new annexaDiv();
                var nameSendToSign = HelperService.getAllName(signing.signProcess.userSendedToSign);
                if(signing.userMustSign.identifier != $rootScope.LoggedUser.identifier){
                    var nameMustSign = HelperService.getAllName(signing.userMustSign);
                    html.htmlObj.addChildren(
                        new annexaHref(
                            'text-primary tresPuntets tresPuntets50',
                            'annexa.profile({idUser: \''+signing.signProcess.userSendedToSign.identifier+'\'})',
                            nameSendToSign, undefined, nameSendToSign
                        )
                    );
                    html.htmlObj.addChildren(
                        new annexaSpan('>','text-muted')
                    );
                    html.htmlObj.addChildren(
                        new annexaHref(
                            'text-primary tresPuntets tresPuntets50',
                            'annexa.profile({idUser: \''+ signing.userMustSign.identifier+'\'})',
                            nameMustSign, undefined, nameMustSign
                        )
                    );
                }else{
                    html.htmlObj.addChildren(
                        new annexaHref(
                            'text-info linkNoStyle tresPuntets',
                            'annexa.profile({idUser: \''+signing.signProcess.userSendedToSign.identifier+'\'})',
                            nameSendToSign, undefined, nameSendToSign
                        )
                    );
                }
                var dateHtml = undefined;
                if(signing.signProcess != null && signing.signProcess.endDate != null) {
                    dateHtml = new annexaSpan($filter('date')(new Date(signing.signProcess.endDate), 'dd/MM/yyyy HH:mm'),'text-muted');
                }
                if(dateHtml && dateHtml.htmlObj){
                    return html.htmlObj.getHtml() + dateHtml.htmlObj.getHtml();
                }
                return html.htmlObj.getHtml();

            }

            $scope.setFilterUrgent = function(tipus){
                if(tipus == 'urgents'){
                    $scope.filterUrgent = 'true';
                }else if(tipus == 'nourgents'){
                    $scope.filterUrgent = 'false';
                }else{
                    $scope.filterUrgent = '';
                }
                RouteStateFactory.addRouteState($state.current, $scope.filterUrgent, 'ColumnsFilter');
                $scope.doSearchFilter();
            };

            $scope.showIconUrgentHeader = function(tipus){
                if(tipus == 'urgents' &&  $scope.filterUrgent == 'true'){
                    return true;
                }else if(tipus == 'nourgents' && $scope.filterUrgent == 'false'){
                    return true;
                }else if(tipus == 'tots' && $scope.filterUrgent == ''){
                    return true;
                }
                return false;
            };

            var searchUrgentDiv =  '<div ng-include="\'./views/sign/parts/headerUrgentDatatable.html\'"></div>';

            $scope.getCircuit = function(id) {
                $scope.signatureCircuitDocument = {};
                var rowPending = $scope.getRow(id, $scope.tablePending.list);
                if (rowPending) {
                    $scope.getCircuitByRow(rowPending);
                } else {
                    var rowQueued = $scope.getRow(id, $scope.tableQueued.list);
                    if (rowQueued) {
                        $scope.getCircuitByRow(rowQueued);
                    } else {
                        var rowFinished = $scope.getRow(id, $scope.tableFinished.list);
                        if (rowFinished) {
                            $scope.getCircuitByRow(rowFinished);
                        }
                    }
                }
            }

            $scope.getCircuitByRow = function(row){
                if(row) {
                    $scope.signatureCircuitDocument = angular.copy(row.signProcess);
                    if($scope.signatureCircuitDocument.signProcessActions){
                        for(var i=0; i < $scope.signatureCircuitDocument.signProcessActions.length; i++){
                            var state = $.grep($scope.signstatestypes, function (n, j) {if (n.id == $scope.signatureCircuitDocument.signProcessActions[i].signState) {return n;} else {return;}});
                            if(state && state.length > 0){
                                $scope.signatureCircuitDocument.signProcessActions[i].processActionState = state[0];
                            }
                        }
                    }
                }else{
                    $scope.signatureCircuitDocument = {};
                }
                $timeout(function () {
                    angular.element('#closeButtonSignCircuit').focus();
                })
            }

            var divDates = function(data, type, signing, meta){
                var div3 = new annexaDiv();
                if(signing.actionOrder != 1) {
                    if(signing.signProcess && signing.signProcess.signProcessActions && signing.signProcess.signProcessActions.length >= signing.actionOrder){
                    	var signProcessActionsOrder = $linq(signing.signProcess.signProcessActions).orderBy("x => x.id").toArray();
                    	var lastAction = signProcessActionsOrder[signing.actionOrder-2];
                    	if (lastAction.endDate) {
                    		var actionHtml = new annexaSpan('');
                    		var strong1 = new annexaStrong('');
                    		var span1 = undefined;
                    		if(lastAction.signState==apiSign.SIGNSTATE_REJECTED.id){
                    			span1 = new annexaSpan($filter('translate')('global.sign.finished.rejected'));
                    		}else{
	                            if(lastAction.signActionType.code==apiSign.SIGNATURE_CODE || lastAction.signActionType.code == apiSign.SIGNATURE_RECOGNIZED_CODE || lastAction.signActionType.code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE || lastAction.signActionType.code == apiSign.SIGNATURE_BIO_SIGN_CODE){
	                                span1 = new annexaSpan($filter('translate')('global.sign.finished.signed'));
	                            }else{
	                                span1 = new annexaSpan($filter('translate')('global.sign.finished.validated'));
	                            }
                    		}
                    		strong1.htmlObj.addChildren(span1);
                    		var span1_2 = new annexaSpan($filter('translate')('global.literals.the'));
                    		actionHtml.htmlObj.addChildren(strong1);
                    		div3.htmlObj.addChildren(actionHtml);
                    		div3.htmlObj.addChildren(span1_2);
                    		var br_1 = new annexaBr();
                    		div3.htmlObj.addChildren(br_1);
                    		var html4 = new annexaSpan($filter('date')(new Date(lastAction.endDate), 'dd/MM/yyyy HH:mm'));
                    		div3.htmlObj.addChildren(html4);
                    	}
                    }
                }
                if(signing.signProcess != null && signing.signProcess != undefined && (signing.signProcess.endDate == null && signing.signProcess.endDate == undefined)){
                    if(signing.startDate != null && signing.startDate != undefined && signing.maxDurationDays != null && signing.maxDurationDays != undefined && signing.maxDurationDays > 0) {
                        var finalDate = new Date(signing.startDate);
                        finalDate.setDate(finalDate.getDate() + signing.maxDurationDays);
                        var numDay =  finalDate - new Date();
                        numDay = Math.floor(numDay / (1000 * 60 * 60 * 24));
                        var div2 = new annexaDiv();
                        var html5 =new annexaSpan('');
                        html5.htmlObj.addAttribute('translate', 'global.sign.pending.limitDate');
                        div2.htmlObj.addChildren(html5);
                        if(numDay < 0){
                            var html6 =new annexaSpan(numDay,'text-danger');
                            div2.htmlObj.addChildren(html6);
                        }else{
                            var html7 =new annexaSpan(numDay+' ');
                            div2.htmlObj.addChildren(html7);
                        }
                        return div3.htmlObj.getHtml() + div2.htmlObj.getHtml();

                    }
                }
                return div3.htmlObj.getHtml();
            }

            var divDocInfo = function(data, type, signing, meta){
                var div = new annexaDiv('row ');
                var div2 = new annexaDiv('col-md-2 p-r-0');
                var ci1 = new annexaCircleicon(signing.signProcess.document.type.style,'',signing.signProcess.document.type.acronym, 'text-white');
                div2.htmlObj.addChildren(ci1);
                div.htmlObj.addChildren(div2);

                var div3 = new annexaDiv('col-md-7');

                var a1 = new annexaClickHref('text-primary','viewDocument('+ signing.signProcess.document.id +')','');
                var strong1 = new annexaStrong('');
                var span1 = new annexaSpan();
                span1.htmlObj.addAttribute('translate', 'global.literals.seeDocument');
                strong1.htmlObj.addChildren(span1);
                a1.htmlObj.addChildren(strong1);
                div3.htmlObj.addChildren(a1);

                var p1 = new annexaDiv('m-b-0 text-nowrap','');
                var a2 = new annexaHref(' inline v-t text-sm','annexa.doc.documents.view({code: \''+signing.signProcess.document.id+'\'})','','_blank');
                var span2 = new annexaSpan('[', 'text-muted');
                var span3 = new annexaSpan(undefined, 'text-muted');
                span3.htmlObj.addAttribute('translate', 'global.sign.literals.profile');
                var span4 = new annexaSpan(']', 'text-muted');
                a2.htmlObj.addChildren(span2);
                a2.htmlObj.addChildren(span3);
                a2.htmlObj.addChildren(span4);
                p1.htmlObj.addChildren(a2);
                if(signing.signProcess.document.dossierTransactionDocuments  && signing.signProcess.document.dossierTransactionDocuments.length > 0) {
                    var dossierTransactionColumn = new annexaDossierTransactionColumn($scope,signing.signProcess.id, ' inline text-grey', $linq(signing.signProcess.document.dossierTransactionDocuments).select("x => x.dossierTransaction").toArray(), '[', ']');
                    p1.htmlObj.addChildren(dossierTransactionColumn);
                }
                div3.htmlObj.addChildren(p1);
                div.htmlObj.addChildren(div3);
                return div.htmlObj.getHtml();
            }

            var divUrgent = function(data, type, signing, meta){
                if(signing.signProcess.urgent){
                    if(signing.signProcess.urgentReason) {
                        var ci1 = new annexaCircleicon('warn',signing.signProcess.urgentReason,'', '','sm', 'fa-exclamation text-right text-danger');
                        return ci1.htmlObj.getHtml()
                    }else{
                        var ci1 = new annexaCircleicon('warn','','','', 'sm','fa-exclamation text-right text-danger');
                        return ci1.htmlObj.getHtml();
                    }
                }
                return '';
            }

            var divComment = function(data, type, signing, meta){
                var comments = '';
                var commentsBool = false;
                if(signing.signProcess && signing.signProcess.signProcessActions && signing.signProcess.signProcessActions.length > 0){
                	if(signing.signProcess.comments){
                		comments = ((signing.signProcess.userSendedToSign && signing.signProcess.userSendedToSign.identifier)? signing.signProcess.userSendedToSign.identifier+' -> ': '') + signing.signProcess.comments;
            			commentsBool = true;
                	}
                	_.forEach(signing.signProcess.signProcessActions, function(spa){
                		if(spa.comments){
                			comments = comments + '\n' + ((spa.userSign && spa.userSign.identifier)?spa.userSign.identifier+' -> ':'') + spa.comments;
                			commentsBool = true;
                		}
                		
                	});
                }else if(signing.comments){
                    comments = signing.comments;
                    commentsBool = true;
                }
                var div = new annexaDiv('text-center');
                var a1 = new annexaModalHref('','#observations-modal-'+signing.id,'addCommentsToArray('+signing.id+')','','','',comments,'am-fade-and-slide-top');
                var icon1 = new annexaIcon('text-grey text-lg','{\'fa-comment\':'+commentsBool+' , \'fa-comment-o\':'+ !commentsBool+'}');
                a1.htmlObj.addChildren(icon1);
                div.htmlObj.addChildren(a1);

                var div2 = new annexaDiv('modal black-overlay', 'observations-modal-'+signing.id, false, 'dialog');
                var div3 = new annexaDiv('modal-dialog');
                var div4 = new annexaDiv('modal-content');
                var div5 = new annexaDiv('modal-header');
                var h5 = new htmlObject('h5',[new htmlAttribute('class', 'modal-title')],'');
                var span1 = new annexaSpan($filter('translate')('global.literals.observations'));
                h5.addChildren(span1);
                div5.htmlObj.addChildren(h5);
                div4.htmlObj.addChildren(div5);
                div3.htmlObj.addChildren(div4);
                div2.htmlObj.addChildren(div3);
                var div6 = new annexaDiv('modal-body p-lg');
                var div7 = new annexaDiv('row');
                if(signing.signProcess && signing.signProcess.document && signing.signProcess.document.observations){
                    var div8 = new annexaDiv('col-sm-12');
                    var span2 = new annexaSpan(signing.signProcess.userSendedToSign.identifier+' -> '+signing.signProcess.document.observations);
                    div8.htmlObj.addChildren(span2);
                    div7.htmlObj.addChildren(div8);
                }
                var div9 = new annexaDiv('','','','','\'./views/sign/parts/otherComments.html\'');
                div7.htmlObj.addChildren(div9);
                div6.htmlObj.addChildren(div7);
                div4.htmlObj.addChildren(div6);
                var div10 = new annexaDiv('row m-t');
                var div11 = new annexaDiv('col-sm-12');
                var textArea1 = new annexaTextarea('col-sm-11', 500, 5, 'delegatecomments.comment'+signing.id, signing.signState == apiSign.SIGNSTATE_REJECTED.id, $filter('translate')('global.sign.literals.observationDoc'));
                div11.htmlObj.addChildren(textArea1);
                div10.htmlObj.addChildren(div11);
                div6.htmlObj.addChildren(div10);
                var div12 = new annexaDiv('modal-footer');
                var button1 = new annexaModalButton('btn btn-sm grey-500  text-white ', undefined, '$hide()', undefined, $filter('translate')('global.literals.cancel'),undefined, undefined, 'modal', undefined);
                var button2 = new annexaModalButton('btn btn-sm danger  text-white ', undefined,'clearComments('+signing.id+')', undefined,$filter('translate')('global.literals.clear'),undefined, undefined, 'modal', signing.signState == apiSign.SIGNSTATE_REJECTED.id);
                var button3 = new annexaModalButton('btn btn-sm primary', undefined, 'addComments('+signing.id+')', undefined, $filter('translate')('global.sign.literals.update'),'fa-check', undefined, 'modal', signing.signState == apiSign.SIGNSTATE_REJECTED.id);
                div12.htmlObj.addChildren(button3);
                div12.htmlObj.addChildren(button2);
                div12.htmlObj.addChildren(button1);
                div4.htmlObj.addChildren(div12);
                return div.htmlObj.getHtml() + div2.htmlObj.getHtml();

            }
            var divCommentFinal = function(data, type, signing, meta){
                var comments = '';
                var commentsBool = false;
                if(signing.signProcess && signing.signProcess.signProcessActions && signing.signProcess.signProcessActions.length > 0){
                	if(signing.signProcess.comments){
                		comments = ((signing.signProcess.userSendedToSign && signing.signProcess.userSendedToSign.identifier)? signing.signProcess.userSendedToSign.identifier+' -> ': '') + signing.signProcess.comments;
            			commentsBool = true;
                	}
                	_.forEach(signing.signProcess.signProcessActions, function(spa){
                		if(spa.comments){
                			comments = comments + '\n' + ((spa.userSign && spa.userSign.identifier)?spa.userSign.identifier+' -> ':'') + spa.comments;
                			commentsBool = true;
                		}
                	});
                }else if(signing.comments){
                    comments = signing.comments;
                    commentsBool = true;
                }
                var div = new annexaDiv('text-center');
                var icon1 = new annexaIcon('text-grey text-lg','{\'fa-comment\':'+commentsBool+' , \'fa-comment-o\':'+ !commentsBool+'}', ((comments && comments != '')?comments:undefined));
                div.htmlObj.addChildren(icon1);
                return div.htmlObj.getHtml();

            }
            var divActions = function(data, type, signing, meta){
            	var buttonSignClick;
            	if (signing.signActionType.code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE) {
            		buttonSignClick = 'signWizardCloudSignAndorra('+signing.id+')';            		
            	}else if (signing.signActionType.code == apiSign.SIGNATURE_BIO_SIGN_CODE) {
                	buttonSignClick = 'signWizardBIOSIGN('+signing.id+')';            		
                } else {
            		buttonSignClick = 'signWizard('+signing.id+')';
            	}
            	
                var div = new annexaDiv(' btn-group btn-flexgroup');
                var button1 = new annexaButton('btn primary',buttonSignClick,undefined,$filter('translate')('global.sign.literals.ToSign'), 'fa-pencil-square-o');
                div.htmlObj.addChildren(button1);
                var button2 = new annexaButton('grey-500 text-white','removeFromQueue('+signing.id+')','sr-only',$filter('translate')('global.sign.queued.remove_from_queue'), 'fa-close',$filter('translate')('global.sign.queued.remove_from_queue'));
                div.htmlObj.addChildren(button2);                
                return div.htmlObj.getHtml();
            }

            var divRejected = function(data, type, signing, meta){
                var div = new annexaDiv();
                if(signing.signState==apiSign.SIGNSTATE_REJECTED.id){
                    var strong1 = new annexaStrong('');
                    var icon1 = new annexaIcon('fa-close text-danger m-r-sm');
                    var span1 = new annexaSpan($filter('translate')('global.sign.finished.rejected'));
                    strong1.htmlObj.addChildren(icon1);
                    strong1.htmlObj.addChildren(span1);
                    div.htmlObj.addChildren(strong1);
                }else{
                    var span2 = new annexaSpan('',undefined,(signing.signActionType.code==apiSign.SIGNATURE_CODE || signing.signActionType.code==apiSign.SIGNATURE_RECOGNIZED_CODE || signing.signActionType.code==apiSign.SIGNATURE_CLOUD_ANDORRA_CODE || signing.signActionType.code==apiSign.SIGNATURE_BIO_SIGN_CODE));
                    var strong2 = new annexaStrong('');
                    var icon2 = new annexaIcon('fa-check text-success m-r-sm');
                    var span3 = new annexaSpan($filter('translate')('global.sign.finished.signed'));
                    strong2.htmlObj.addChildren(icon2);
                    strong2.htmlObj.addChildren(span3);
                    span2.htmlObj.addChildren(strong2);
                    div.htmlObj.addChildren(span2);

                    var span4 = new annexaSpan('',undefined,signing.signActionType.code==apiSign.VALIDATE_CODE);
                    var strong3 = new annexaStrong('');
                    var icon3 = new annexaIcon('fa-check text-success m-r-sm');
                    var span5 = new annexaSpan($filter('translate')('global.sign.finished.validated'));
                    strong3.htmlObj.addChildren(icon3);
                    strong3.htmlObj.addChildren(span5);
                    span4.htmlObj.addChildren(strong3);
                    div.htmlObj.addChildren(span4);
                }
                if(signing.endDate != null) {
                    var br1 = new annexaBr();
                    var span6 = new annexaSpan($filter('date')(signing.endDate, 'dd/MM/yyyy HH:mm'),'text-muted');
                    div.htmlObj.addChildren(br1);
                    div.htmlObj.addChildren(span6);
                }
                return div.htmlObj.getHtml();
            }
            var divProcess = function(data, type, signing, meta){
                var div = new annexaDiv(' btn-group  text-right');
                var button1 = new annexaModalButton('primary','#signCircuit','getCircuit('+signing.id+')','',$filter('translate')('global.sign.literals.seeProcess'));
                div.htmlObj.addChildren(button1);
                return div.htmlObj.getHtml();
            }

            var divSignType = function(data, type, full, meta) {
                var content = '';

                if(data.code == apiSign.SIGNATURE_RECOGNIZED_CODE) {
                    content = $filter('translate')('global.literals.signTypeReconized');
                } else if(data.code == apiSign.SIGNATURE_CODE) {
                    content = $filter('translate')('global.literals.signTypeAdvanced');
                } else if(data.code == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE) {
                    content = $filter('translate')('global.literals.signTypeCloudAndorra');
                } else if(data.code == apiSign.SIGNATURE_BIO_SIGN_CODE) {
                    content = $filter('translate')('global.literals.signTypeBIOSIGN');
                }

                return content;
            }

            var getFilterCallPending = function(){
                var filterCallOrig = TableFilterFactory.getFilterCall($scope.tfilter);
                var filterCall = {};
                filterCall.userMustSign ={id: $rootScope.LoggedUser.id};
                if(filterCallOrig && filterCallOrig.signactiontype){
                    filterCall.signActionType = filterCallOrig.signactiontype;
                }
                if((filterCallOrig && filterCallOrig.documenttype) || $scope.filterUrgent || filterCallOrig.title){
                    filterCall.signProcess = {document:{}};
                    if(filterCallOrig && filterCallOrig.documenttype) {
                        filterCall.signProcess.document.type = filterCallOrig.documenttype;
                    }
                    if($scope.filterUrgent){
                        filterCall.signProcess.urgent = $scope.filterUrgent;
                    }
                    if(filterCallOrig && filterCallOrig.title) {
                        filterCall.signProcess.document.name = filterCallOrig.title;
                    }
                }
                filterCall.comments = filterCallOrig.observations;
                return filterCall;
            }
            var getFilterCallAuxPending = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.finished = false;
                filterCallAux.pending = false;
                return filterCallAux;
            }

            var columnsAux = [
                {id: 'signProcess.urgent', width: '56px', title: searchUrgentDiv, render:divUrgent, sortable: false},
                {id: 'signProcess.userSendedToSign.identifier', title: $filter('translate')('global.sign.pending.signers'), render:divSignants, sortable: false},
                {id: 'startDate', title: $filter('translate')('global.sign.pending.dates'), render: divDates},
                {id: 'signProcess.document.code', width: '180px', title: $filter('translate')('global.literals.document'), render:divDocInfo},
                {id: 'signProcess.document.name', width: '400px', title: $filter('translate')('global.literals.title')},
                {id: 'comments', title: '<div class="column-comments">' + $filter('translate')('global.literals.observations')+'</div>', render: divComment},
                {id: 'signActionType', title: $filter('translate')('global.literals.signType'), render: divSignType},
                { id: 'actions', columnName: 'signActionType.id', width: '200px', className: 'text-center', title: '<div class="column-actions">' + $filter('translate')('global.literals.actions')+'</div>', render: divDocument, sortable: false}
            ];
            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sign_pending', columnsAux);
            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sign_pending', columnsAux);
            $scope.tableOrderProperties = {sortName: 'startDate', sort: [[2,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tablePending = {
                id: 'tablePending',
                origin: 'sign',
                objectType: 'SignProcessAction',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: getFilterCallPending(),
                filterCallAux: getFilterCallAuxPending(),
                filterCallFunc: getFilterCallPending,
                filterCallAuxFunc: getFilterCallAuxPending,
                columns: columns,
                containerScope: $scope,
                list:[]
            };

            var getFilterCallQueued = function(){
                var filterCall = {userMustSign:{id: $rootScope.LoggedUser.id}};
                return filterCall;
            }
            var getFilterCallAuxQueued = function(){
                var filterCallAux = {finished: false, pending: true};
                filterCallAux.signActionTypeCode = $scope.signActionTypeCode;
                return filterCallAux;
            }

            var columnsQueuedAux = [
                {id: 'signProcess.urgent', width: '40px', render:divUrgent, sortable: false},
                {id: 'actual', width: '50px', title: $filter('translate')('global.sign.pending.signers'), render:divSignants, sortable: false},
                {id: 'startDate', width: '50px', title: $filter('translate')('global.sign.pending.dates'), render: divDates},
                {id: 'signProcess.document.code', width: '180px', title: $filter('translate')('global.literals.document'), render:divDocInfo},
                {id: 'signProcess.document.name', width: '50px', title: $filter('translate')('global.literals.title')},
                {id: 'comments', width: '40px', title: '<div class="column-comments">' + $filter('translate')('global.literals.observations')+'</div>', render: divComment},
                {id: 'signActionType', title: $filter('translate')('global.literals.signType'), render: divSignType},
                { id: 'actions', columnName: 'signActionType.id', width: '200px', className: 'text-center', title: '<div class="column-actions">' + $filter('translate')('global.literals.actions')+'</div>', render: divActions, sortable: false}
            ];
            var columnsQueued = getDatatableColumnsSettings(datatableSettings, 'datatable_sign', columnsQueuedAux);
            var orderPropertiesQueued = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sign', columnsQueuedAux);
            $scope.tableOrderPropertiesQueued = {sortName: 'startDate', sort: [[2,'desc']]};
            if(orderPropertiesQueued && orderPropertiesQueued.name && orderPropertiesQueued.direction && orderPropertiesQueued.index > -1){
                $scope.tableOrderPropertiesQueued = {sortName: orderPropertiesQueued.name, sort: [[orderPropertiesQueued.index,orderPropertiesQueued.direction]]};
            }

            $scope.tableQueued = {
                id: 'tableQueued',
                origin: 'sign',
                objectType: 'SignProcessAction',
                iDisplayLength: 1000,
                stateSave: false,
                sortName: $scope.tableOrderPropertiesQueued.sortName,
                sort: $scope.tableOrderPropertiesQueued.sort,
                filterCall: getFilterCallQueued(),
                filterCallAux: getFilterCallAuxQueued(),
                filterCallFunc: getFilterCallQueued,
                filterCallAuxFunc: getFilterCallAuxQueued,
                columns: columnsQueued,
                containerScope: $scope,
                list:[]
            };

            $scope.showQueuedTable = function(){
                var show = 'KO';
                if($scope.tableQueued && $scope.tableQueued.list && $scope.tableQueued.list.length > 0){
                    var recognized = $linq($scope.tableQueued.list).where("x => x.signActionType.code == '"+apiSign.SIGNATURE_RECOGNIZED_CODE+"'").toArray();
                    if(recognized && recognized.length > 0){
                        if($scope.appletCertificates && $scope.appletCertificates.length > 0) {
                            $rootScope.loading(false);
                            show = 'OK';
                        }else{
                            if($scope.appletReturnCertsExecuted) {
                                $rootScope.loading(false);
                                show = 'OK';
                            }else{
                                $rootScope.loading(true);
                                show = 'Applet';
                            }
                        }
                    }else{
                        $rootScope.loading(false);
                        show = 'OK';
                    }
                }
                return show;
            }
                        
            var getFilterCallFinished = function(){
                var filterCallOrig = TableFilterFactory.getFilterCall($scope.tfilterFinish);
                var filterCall = {};
                filterCall.userMustSign ={id: $rootScope.LoggedUser.id};
                if(filterCallOrig && filterCallOrig.signactiontype){
                    filterCall.signActionType = filterCallOrig.signactiontype;
                }
                if((filterCallOrig && filterCallOrig.documenttype) || filterCallOrig.title){
                    filterCall.signProcess = {document:{}};
                    if(filterCallOrig && filterCallOrig.documenttype) {
                        filterCall.signProcess.document.type = filterCallOrig.documenttype;
                    }
                    if(filterCallOrig && filterCallOrig.title) {
                        filterCall.signProcess.document.name = filterCallOrig.title;
                    }
                }
                filterCall.comments = filterCallOrig.observations;
                return filterCall;
            }
            
            var getFilterCallAuxFinished = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilterFinish);
                filterCallAux.finished = true;
                filterCallAux.pending = true;
                return filterCallAux;
            }

            var columnsFinishedAux = [
                {id: 'endDate', title:$filter('translate')('global.literals.actionDo'), render:divRejected},
                {id: 'actual', width: '50px', title: $filter('translate')('global.sign.pending.signers'), render:divSignantsFinish, sortable: false},
                {id: 'signProcess.document.code', title: '<div class="w-180">' + $filter('translate')('global.literals.document') + '</div>', render:divDocInfo},
                {id: 'signProcess.document.name', title: $filter('translate')('global.literals.title')},
                {id: 'comments', width: '40px', title: '<div class="column-comments">' + $filter('translate')('global.literals.observations')+'</div>', render: divCommentFinal},
                {id: 'signActionType', title: $filter('translate')('global.literals.signType'), render: divSignType},
                { id: 'actions', columnName: 'id', width: '200px', className: 'text-center', title: '<div class="column-actions">' + $filter('translate')('global.literals.actions')+'</div>', render: divProcess, sortable: false}
            ];
            var columnsFinished = getDatatableColumnsSettings(datatableSettings, 'datatable_sign', columnsFinishedAux);
            var orderPropertiesFinished = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sign', columnsFinishedAux);
            $scope.tableOrderPropertiesFinished = {sortName: 'endDate', sort: [[0,'desc']]};
            if(orderPropertiesFinished && orderPropertiesFinished.name && orderPropertiesFinished.direction && orderPropertiesFinished.index > -1){
                $scope.tableOrderPropertiesFinished = {sortName: orderPropertiesFinished.name, sort: [[orderPropertiesFinished.index,orderPropertiesFinished.direction]]};
            }

            $scope.tableFinished = {
                id: 'tableFinished',
                origin: 'sign',
                objectType: 'SignProcessAction',
                sortName: $scope.tableOrderPropertiesFinished.sortName,
                sort: $scope.tableOrderPropertiesFinished.sort,
                filterCall: getFilterCallFinished(),
                filterCallAux: getFilterCallAuxFinished(),
                filterCallFunc: getFilterCallFinished,
                filterCallAuxFunc: getFilterCallAuxFinished,
                columns: columnsFinished,
                containerScope: $scope,
                list:[]
            };

            /********** FI REFACTORITZACIÓ **************/


            $scope.alertModal = {signing:'', title:'', description:'', action:'', nameButtonCancel:'', nameButton:'', iconButton:'', rejected:false, rejectedComment:''};
            $scope.delegateModal = {userIdentifier:'', user:{}, minDate:new Date(), dateStart:new Date(), dateEnd:new Date(), error:false, errorDescripcio:'', active:false, activeRebudes:false, delgacioFeta:{}, delegacionsRebudes:[], dateopened:false, dateOptions: { dateDisabled: false, formatYear: 'yyyy', maxDate: new Date(2020, 5, 22), minDate: new Date(), startingDay: 1 }};
            $scope.delegateModal.dateopen = function() {
                $scope.delegateModal.dateopened = true;
            };
            $scope.delegatecomments = {};
            $scope.otherComments = [];
            $scope.checkQueued = {};

            function disabled(data) {
                var date = data.date,
                    mode = data.mode;
                return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            }

            $scope.modalConfigConflict = new ModalConfigConflict($scope,'SIGN');
            $scope.modalSingConflict = new ModalSignConflict($scope,'SIGN');

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.sign' || 
                		message.state.name == 'annexa.sign.selectSignActionType' ||
                		message.state.name == 'annexa.sign.queue' ||
                		message.state.name == 'annexa.sign.serverSign' ||
                		message.state.name == 'annexa.sign.applet' || 
                		message.state.name == 'annexa.sign.cloudSignAndorra' ||
                		message.state.name == 'annexa.sign.bioSign' ||
                		message.state.name == 'annexa.sign.finished'){
                	
                    $scope.appletReturnCertsExecuted = true;
                    if($scope.isIE()){
                        $scope.appletReturnCertsExecuted = false;
                    }

					//Elimina el filtro por tipo de firma de la tabla tableQueued
                    $scope.signActionTypeCode = undefined;
                    $scope.tableQueued.filterCallAux =  $scope.tableQueued.filterCallAuxFunc(); 
                    
                    var sign = '';
                    var queued = '';
                    var finish = '';
                    $scope.getDelegations();
                    if(message.state.name == 'annexa.sign'){
                        sign = 'active';
                        $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];                        
                    }else if(message.state.name == 'annexa.sign.selectSignActionType'){
                    	queued = 'active';
                    }else if(message.state.name == 'annexa.sign.queue'){
                        queued = 'active';
                        $rootScope.subHeadButtons = [
                            new HeadButton('btn primary breadDivButton ml-sm-pt', '', '#signpending', 'fa-pencil-square-o', 'global.sign.queued.init_sign', undefined, 'signWizard', 'getSelectedCount'),
                            //new HeadButton('btn primary breadDivButton ','','#sign-modal','fa-pencil-square-o','global.sign.queued.init_sign',undefined,'initSignModal','getSelectedCount'),
                            new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt','modal','#sign-modal','fa-cog','global.sign.queued.preferences', undefined, 'focusSignModal')
                        ];
                    }else if(message.state.name == 'annexa.sign.serverSign'){
                        queued = 'active';
                        $rootScope.subHeadButtons = [
                            new HeadButton('btn primary breadDivButton ml-sm-pt', '', '#signpending', 'fa-pencil-square-o', 'global.sign.queued.init_sign', undefined, 'signWizard', 'getSelectedCount'),
                            //new HeadButton('btn primary breadDivButton ','','#sign-modal','fa-pencil-square-o','global.sign.queued.init_sign',undefined,'initSignModal','getSelectedCount'),
                            new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt','modal','#sign-modal','fa-cog','global.sign.queued.preferences', undefined, 'focusSignModal')
                        ];
                        
                        //Indica que solo debe mostrar los documentos con el tipo de firma con codigo SIGN de la tabla tableQueued   
                        $scope.signActionTypeCode = apiSign.SIGNATURE_CODE;
                        $scope.tableQueued.filterCallAux =  $scope.tableQueued.filterCallAuxFunc(); 
                    }else if(message.state.name == 'annexa.sign.cloudSignAndorra'){
                        queued = 'active';
                        $rootScope.subHeadButtons = [                            
                            new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt','modal','#sign-modal','fa-cog','global.sign.queued.preferences', undefined, 'focusSignModal')
                        ];
                        
                        //Indica que solo debe mostrar los documentos con el tipo de firma con codigo CLOUDSIGN_ANDORRA de la tabla tableQueued   
                        $scope.signActionTypeCode = apiSign.SIGNATURE_CLOUD_ANDORRA_CODE;
                        $scope.tableQueued.filterCallAux =  $scope.tableQueued.filterCallAuxFunc();    
                    }else if(message.state.name == 'annexa.sign.bioSign'){
                        queued = 'active';
                        $rootScope.subHeadButtons = [                            
                            new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt','modal','#sign-modal','fa-cog','global.sign.queued.preferences', undefined, 'focusSignModal')
                        ];
                        
                        //Indica que solo debe mostrar los documentos con el tipo de firma con codigo BIO_SIGN de la tabla tableQueued   
                        $scope.signActionTypeCode = apiSign.SIGNATURE_BIO_SIGN_CODE;
                        $scope.tableQueued.filterCallAux =  $scope.tableQueued.filterCallAuxFunc();    
                    }else if(message.state.name == 'annexa.sign.applet'){
                        queued = 'active';
                        $rootScope.subHeadButtons = [
                            new HeadButton('btn primary breadDivButton ml-sm-pt', '', '#signpending', 'fa-pencil-square-o', 'global.sign.queued.init_sign', undefined, 'signWizard', 'getSelectedCount'),
                            //new HeadButton('btn primary breadDivButton ','','#sign-modal','fa-pencil-square-o','global.sign.queued.init_sign',undefined,'initSignModal','getSelectedCount'),
                            new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt','modal','#sign-modal','fa-cog','global.sign.queued.preferences', undefined, 'focusSignModal')
                        ];     
                        
                        //Indica que solo debe mostrar los documentos con el tipo de firma con codigo LOCALSIGN de la tabla tableQueued   
                        $scope.signActionTypeCode = apiSign.SIGNATURE_RECOGNIZED_CODE;
                        $scope.tableQueued.filterCallAux =  $scope.tableQueued.filterCallAuxFunc(); 
                    }else if(message.state.name == 'annexa.sign.finished'){
                        finish = 'active';
                        $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    }
                    
                    if(!$scope.delegateModal.active && (!$scope.delegateModal.delgacioFeta || !$scope.delegateModal.delgacioFeta.id)) {
                        $rootScope.headButtons = [new HeadButton('btn btn-grey-300 mb-xs-pt mt-sm-pt', 'modal', '#delegate-modal','fa-external-link', 'global.sign.pending.active_delegation',undefined,'getDelegations')];
                    }else if(!$scope.delegateModal.active && $scope.delegateModal.delgacioFeta && $scope.delegateModal.delgacioFeta.id){
                        if( $scope.delegateModal.delgacioFeta.startDelegationDate.time) {
                            $rootScope.headButtons = [new HeadButton('btn btn-success mb-xs-pt', 'modal', '#delegate-modal', 'fa-external-link', 'global.sign.pending.activated_delegation2', $filter('date')(new Date($scope.delegateModal.delgacioFeta.startDelegationDate.time), 'dd/MM/yyyy'), 'getDelegations')];
                        }else{
                            $rootScope.headButtons = [new HeadButton('btn btn-success mb-xs-pt', 'modal', '#delegate-modal', 'fa-external-link', 'global.sign.pending.activated_delegation2', $filter('date')(new Date($scope.delegateModal.delgacioFeta.startDelegationDate), 'dd/MM/yyyy'), 'getDelegations')];
                        }
                    }else{
                        $rootScope.headButtons = [new HeadButton('btn btn-success mb-xs-pt', 'modal', '#delegate-modal','fa-external-link', 'global.sign.pending.activated_delegation',undefined,'getDelegations')];
                    }
                    
                    RestService.findByUrl(apiSign.countSignProcessActionsByFilter + "?userId=" + $rootScope.LoggedUser.id + "&finished=false&pending=false").then(function(data){

                        if(data && data.data > 0){
                            $scope.numPending = data.data;
                        }else{
                            $scope.numPending = 0;
                        }
                        RestService.findByUrl(apiSign.countSignProcessActionsByFilter + "?userId=" + $rootScope.LoggedUser.id + "&finished=false&pending=true").then(function(data){
                            if (data && data.data > 0) {
                                $scope.numSigning = data.data;
                            } else {
                                $scope.numSigning = 0;
                            }
                            $rootScope.subHeadTabs = [
                                {state:'annexa.sign',  type:'breadDivButton '+sign, name:'global.literals.pending', length:$scope.numPending,idDiv:'#delegate-modal'},
                                {state:'annexa.sign.queue',  type:'breadDivButton '+queued, name:'global.sign.queued.tabtitle', length:$scope.numSigning,idDiv:'#delegate-modal'},
                                {state:'annexa.sign.finished', type:'breadDivButton '+finish, name:'global.literals.tramFinally', length:'',idDiv:'#delegate-modal'}
                            ];
                        }).catch(function(){
                        });
                    }).catch(function(){
                    });
                }
            });
            
            $scope.getDelegations = function() {
                $scope.delegateModal.delgacioFeta = LoginService.getActiveDelegation(true);
                if($scope.delegateModal.delgacioFeta && $scope.delegateModal.delgacioFeta.startDelegationDate && $scope.delegateModal.delgacioFeta.startDelegationDate.time){
                    $scope.delegateModal.delgacioFeta.startDelegationDate = new Date($scope.delegateModal.delgacioFeta.startDelegationDate.time);
                }
                if($scope.delegateModal.delgacioFeta &&$scope.delegateModal.delgacioFeta.endDelegationDate && $scope.delegateModal.delgacioFeta.endDelegationDate.time){
                    $scope.delegateModal.delgacioFeta.endDelegationDate = new Date($scope.delegateModal.delgacioFeta.endDelegationDate.time);
                }
                $scope.delegateModal.active = false
                if($scope.delegateModal.delgacioFeta && new Date($scope.delegateModal.delgacioFeta.startDelegationDate) <= new Date()) {
                    $scope.delegateModal.active = true;
                }
                $timeout(function () {
                    angular.element('#idUsuariDelegateModal').focus();
                })
            }
            $scope.getDelegations();

            HeaderService.changeState($state.current);

            $scope.removeRow=function(row,collection){
                var index = $scope.getRowIndex(row,collection);

                if(index>-1){
                    collection.splice(index, 1);
                }
            }

            $scope.getRow=function(id,collection){
                for(var i=0;i<collection.length;i++){
                    if(collection[i].id==id) return collection[i];
                }
                return false;
            }

            $scope.getRowIndex=function(row,collection){
                for(var i=0;i<collection.length;i++){
                    if(collection[i].id==row.id) return i;
                }
                return -1;
            }
            $scope.addRow=function(row,collection){
                var r = $scope.getRow(row,collection);
                if (!r) {
                    collection.push(row);
                }
            }
            
            $scope.transitionToSignActionType=function(signActionTypeCode, signActionTypeId){
            	if (signActionTypeCode == apiSign.SIGNATURE_CODE) {
            		$state.transitionTo('annexa.sign.serverSign');
            	} else if (signActionTypeCode == apiSign.SIGNATURE_RECOGNIZED_CODE) {
            		$state.transitionTo('annexa.sign.applet');
            	} else if (signActionTypeCode == apiSign.SIGNATURE_CLOUD_ANDORRA_CODE) {
            		$state.transitionTo('annexa.sign.cloudSignAndorra');
            	} else if (signActionTypeCode == apiSign.SIGNATURE_BIO_SIGN_CODE) {
            		$state.transitionTo('annexa.sign.bioSign');
            	}
            }
            
            $scope.addToQueue=function(id){
            	var rowAux = $scope.getRow(id,$scope.tablePending.list);
                if(!$scope.getRow(id,$scope.tableQueued.list)){

                    SignService.addToSign(id).then(function(dataUpdate){
                        $scope.tableQueued.list.push(rowAux);
                        $scope.tablePending.reloadInternalData(false,true);
                        HeaderService.changeState($state.current);
                    });
                }
            }

            $scope.removeFromQueue=function(id){

                SignService.removeFromSign(id).then(function (dataUpdate) {
                    HeaderService.changeState($state.current);
                    var row = $scope.getRow(id,$scope.tableQueued.list);
                    $scope.removeRow(row,$scope.tableQueued.list);                    
                    if($scope.tablePending && $scope.tablePending.reloadInternalData){
                        $scope.tablePending.reloadInternalData(false,true);
                    }
                    if($scope.tableQueued && $scope.tableQueued.reloadInternalData) {
                        $scope.tableQueued.reloadInternalData(callbackInstance1, true);
                    }
                });
            }

            $scope.finishRow=function(row){
                $scope.removeRow(row,$scope.tablePending.list);
                $scope.addRow(row,$scope.tableFinished.list);
            }

            $scope.marcarAccioAlert = function(accio, id, iddoc){
                var signing = $scope.getRow(id,$scope.tablePending.list);
                if(accio == 'reject'){
                    $scope.alertModal.signing = signing;
                    $scope.alertModal.action = 'reject';
                    $scope.alertModal.title = $filter('translate')('global.sign.modalAlert.reject');
                    $scope.alertModal.description = $filter('translate')('global.sign.modalAlert.rejectReason');
                    $scope.alertModal.nameButton =$filter('translate')('global.sign.literals.rejectSign');
                    $scope.alertModal.iconButton = "fa-close";
                    $scope.alertModal.nameButtonCancel = $filter('translate')('global.literals.cancel');
                    $scope.alertModal.iddoc = iddoc;
                    $scope.alertModal.rejected = true;
                    $scope.alertModal.rejectedComment = '';
                }else{
                    $scope.alertModal.signing = signing;
                    $scope.alertModal.action = 'validar';
                    $scope.alertModal.title = $filter('translate')('global.sign.modalAlert.validate');
                    $scope.alertModal.description = $filter('translate')('global.sign.modalAlert.validateReason');
                    $scope.alertModal.nameButton =$filter('translate')('global.sign.literals.validate');
                    $scope.alertModal.iconButton = "fa-check";
                    $scope.alertModal.nameButtonCancel = $filter('translate')('global.literals.cancel');
                    $scope.alertModal.iddoc = iddoc;
                    $scope.alertModal.rejected = false;
                    $scope.alertModal.rejectedComment = '';
                }
                $timeout(function () {
                    angular.element('#textAreaAlert').focus();
                })
            };

                //recullo delegacions cap a mi//
                $scope.getDelegatedDelegations = function() {
                    RestService.findByUrl(apiSign.getDelegatedDelegations + "?delegated="+$rootScope.LoggedUser.id+"&date1=" + SignService.formatDate(new Date(), true, false) + "&date2=" + SignService.formatDate(new Date(), false, true)).then(function(data){
                        if (data.data && data.data.length > 0){
                            $scope.delegateModal.delegacionsRebudes = JSOG.decode(data.data);
                            $scope.delegateModal.activeRebudes = true;
                        }else{
                            $scope.delegateModal.delegacionsRebudes = [];
                            $scope.delegateModal.activeRebudes = false;
                        }
                    }).catch(function(){
                    });
                }
                $scope.getDelegatedDelegations();

                $scope.signmodal=false;
                $scope.rejected=false;


            $scope.doSearchFilter=function(queued){
                if(queued == false){
                    $scope.tableFinished.reloadInternalData(callbackInstance1,true);
                }else{
                    $scope.tablePending.reloadInternalData(callbackInstance1,true);
                    HeaderService.changeState($state.current);
                }
            }

            //recullo els processos de signatura
            $scope.reloadData = reloadData;
            $scope.tableFinished.table={};

            function reloadData() {
                var resetPaging = false;
                $scope.tablePending.reloadInternalData(callbackInstance1, true);
                $scope.tableQueued.reloadInternalData(callbackInstance1, true);
                $scope.tableFinished.reloadInternalData(callbackInstance1, true);
            }

            function callbackInstance1(json) {
                console.log(json);
            };

            function getIdsSigning(){
                var listIds = [];
                for(var i=0;i<$scope.tableQueued.list.length;i++){
                    listIds.push($scope.tableQueued.list[i].signProcess.id);
                }
                return listIds;
            }

            function initConflictModal(dataValids){
                $scope.modalSingConflict.initModal(dataValids, $scope);
                $("#conflict-sign-modal").modal();
            }

            /*function initSignCertificates(valid, dataValids){
                SignService.getUserCertificates($rootScope.LoggedUser.id).then(function(data){
                    if(data){
                        data = JSOG.decode(data);
                        for(var i=0;i<data.length;i++){
                            data[i].isDelegated=function(){
                                return this.userOwner.id!=$rootScope.LoggedUser.id
                            }
                        }
                        $scope.signmodal.usercertificates=data;
                        $scope.signmodal.certificattriat=$scope.signmodal.usercertificates[0];
                        if($scope.signmodal.usercertificates != null && $scope.signmodal.usercertificates != undefined && $scope.signmodal.usercertificates.length == 1){
                            $scope.signmodal.demanaPin();
                            $scope.signmodal.nextDiv = "#pin-modal";
                            if(valid) {
                                $("#pin-modal").modal();
                            }else{
                                initConflictModal(dataValids);
                            }
                        }else{
                            if(valid) {
                                $("#certificates-modal").modal();
                            }else{
                                initConflictModal(dataValids);
                            }
                        }
                    }else{
                        if(valid) {
                            $("#certificates-modal").modal();
                        }else{
                            initConflictModal(dataValids);
                        }
                    }
                }).catch(function () {
                    if(valid) {
                        $("#certificates-modal").modal();
                    }else{
                        initConflictModal(dataValids);
                    }
                });
            }*/

            function getPosition(valids, horaria, position){
                var mod = position;
                var exist = false;
                if(horaria){
                    mod = (position+1) % 6;
                }else{
                    mod = (position-1) % 6
                }
                if(mod == 0){
                    mod = 6;
                }
                var i = 0;
                while(!exist && i<6){
                    for(var j=0; j<valids.length;j++){
                        if(mod == valids[j]){
                            exist = true;
                            return mod;
                        }
                    }
                    if(!exist){
                        if(horaria){
                            mod = (mod+1) % 6;
                        }else{
                            mod = (mod-1) % 6
                        }
                        if(mod == 0){
                            mod = 6;
                        }
                    }
                    i++;
                }
            }

            var getDocumentsToSign = function(idRowToSign) {
                var documentOcupiedPositions = [];

                _.forEach($scope.tableQueued.list, function(signature) {
                    var ocupiedPositions = [];
                    if(signature.signProcess && signature.signProcess.ocupedPositions && signature.signProcess.ocupedPositions.length > 0){
                    	ocupiedPositions = signature.signProcess.ocupedPositions;
                    }
                    
                    var pushObject = {
                        id: signature.id,
                        actionOrder: signature.actionOrder,
                        document: signature.signProcess.document,
                        positions: ocupiedPositions,
                        position: 0,
                        signActionType: signature.signActionType,
                        firstPage: $scope.signConfigUser.firstPage,
                        comments: signature.comments,
                        docCode: ((signature.signProcess && signature.signProcess.document && signature.signProcess.document.createdDate)?new Date(signature.signProcess.document.createdDate).getFullYear().toString().substr(-2) + signature.signProcess.document.id:signature.signProcess.document.id),
                        addedComment:((signature.signProcess && signature.signProcess.addedCommentInAllSignProcessDocument)?signature.signProcess.addedCommentInAllSignProcessDocument:false)
                    };

                    if($scope.signConfigUser.typeConflict == 'PREGUNTAR' && _.contains(ocupiedPositions, $scope.signConfigUser.position)) {
                    	 if(_.contains(ocupiedPositions, 1) && _.contains(ocupiedPositions, 2) && _.contains(ocupiedPositions, 3) && _.contains(ocupiedPositions, 4) && _.contains(ocupiedPositions, 5) && _.contains(ocupiedPositions, 6)){
                             pushObject.conflict = 'none';
		                 }else{
		                     pushObject.conflict = 'ask';            
		                 }
                    } else if($scope.signConfigUser.typeConflict != 'PREGUNTAR' && _.contains(ocupiedPositions, $scope.signConfigUser.position)) {
                        pushObject.conflict = 'calculate';
                    } else {
                        pushObject.conflict = 'none';
                    }

                    pushObject.isOcupied = function(position) {
                        return _.contains(this.positions, position);
                    }

                    if (!idRowToSign || (idRowToSign && pushObject.id == idRowToSign)) {
                    	documentOcupiedPositions.push(pushObject);
                    }                    
                });

                return documentOcupiedPositions;
            }

            $scope.signWizard = function(idRowToSign) {
            	var documentsToSign;
            	if (idRowToSign && !isNaN(idRowToSign)) {
            		//Firma un solo documento
            		documentsToSign = getDocumentsToSign(idRowToSign);
            	} else {
            		//Firmar todos los documentos
            		documentsToSign = getDocumentsToSign();
            	}

                var modal = angular.copy(SignModals.signWizard);

                _.forEach(modal.wizard.steps, function (step, key) {
                    modal.wizard.steps[key].annexaFormly.model = {};
                    modal.wizard.steps[key].annexaFormly.options = {};
                });

                //region Sign Conflict

                var conflictDocuments = $linq(documentsToSign).where("x => x.conflict == 'ask'").toArray();

                modal.wizard.steps[0].annexaFormly.fields[0].templateOptions.documents = conflictDocuments;

                if(conflictDocuments.length != 0) {
                    modal.wizard.steps[0].disabled = false;
                }

                _.forEach(conflictDocuments, function(document) {
                    modal.wizard.steps[0].annexaFormly.model[document.document.id] = -1;
                })

                //endregion

                
                //region sign comments
                
                var commentDocuments = $linq(documentsToSign).where("x => x.comments && !x.addedComment").toArray();

                modal.wizard.steps[1].annexaFormly.fields[0].templateOptions.documents = commentDocuments;
                
                if(commentDocuments.length != 0 && AnnexaPermissionsFactory.havePermission('can_add_comments_to_sign', true)) {
                    modal.wizard.steps[1].disabled = false;
                }

                _.forEach(commentDocuments, function(document) {
                    modal.wizard.steps[1].annexaFormly.model[document.document.id] = false;
                })

                
                //endregion
                
                //region Server Certificates

                if($linq(documentsToSign).count("x => x.signActionType.code == '" + apiSign.SIGNATURE_CODE + "'") != 0) {
                    modal.wizard.steps[2].disabled = false;
                    modal.wizard.steps[3].disabled = false;
                }

                //endregion

                //region Local Certificates

                var localCertificates = [];

                _.forEach($scope.appletCertificates, function(certificate) {
                    localCertificates.push({
                        id: certificate,
                        alias: certificate,
                        isDelegated: function () {
                            return false;
                        }
                    })
                });

                modal.wizard.steps[4].annexaFormly.fields[0].templateOptions.certificates = localCertificates;

                if($linq(documentsToSign).count("x => x.signActionType.code == '" + apiSign.SIGNATURE_RECOGNIZED_CODE + "'") != 0) {
                    modal.wizard.steps[4].disabled = false;
                }

                //endregion

                modal.wizard.modal = modal;
                modal.wizard.docsToSign = documentsToSign;

                modal.preClose = function() {
                    $scope.tableQueued.reloadInternalData(callbackInstance1,true);
                    $state.go('annexa.sign.finished', {}, {reload: false});
                    HeaderService.changeState($state.current);
                }

                AnnexaFormlyFactory.showModal('signWizardModal', modal, $scope.finishSignWizard, false, true);
            }

            $scope.signWizardBIOSIGN = function(idRowToSign) {            	
            	var documentsToSign = getDocumentsToSign(idRowToSign);
            	var modal = angular.copy(SignModals.signWizardBIOSIGN); 
            	            	
            	modal.wizard.modal = modal;
                modal.wizard.docsToSign = documentsToSign;
                modal.wizard.steps[1].disabled = false;
                _.forEach(modal.wizard.steps, function (step, key) {
                    modal.wizard.steps[key].annexaFormly.model = {};
                    modal.wizard.steps[key].annexaFormly.options = {};
                });

                //region Sign Conflict

                var conflictDocuments = $linq(documentsToSign).where("x => x.conflict == 'ask'").toArray();

                modal.wizard.steps[0].annexaFormly.fields[0].templateOptions.documents = conflictDocuments;

                if(conflictDocuments.length != 0) {
                    modal.wizard.steps[0].disabled = false;
                }

                _.forEach(conflictDocuments, function(document) {
                    modal.wizard.steps[0].annexaFormly.model[document.document.id] = -1;
                })

                //endregion


                modal.preClose = function() {
                    $scope.tableQueued.reloadInternalData(callbackInstance1,true);
                    HeaderService.changeState($state.current);
                }
                
                AnnexaFormlyFactory.showModal('signWizardModal', modal, $scope.finishSignWizardBIOSIGN, false, true);
            }

            
            $scope.signWizardCloudSignAndorra = function(idRowToSign) {            	
            	var documentsToSign = getDocumentsToSign(idRowToSign);
            	var modal = angular.copy(SignModals.signWizardCloudSignAndorra); 
            	            	
            	modal.wizard.modal = modal;
                modal.wizard.docsToSign = documentsToSign;
                modal.wizard.steps[0].disabled = false;

                AnnexaFormlyFactory.showModal('signWizardModal', modal, $scope.finishStep0SignWizardCloudSignAndorra, false, true);
            }

            $scope.finishSignWizard = function ($modal) {
                var self = this;

                var documentsToSign = []

                var getAskDocumentPosition = function(docId, asked) {
                    var retPosition = 0;

                    _.forOwn(asked, function(value, key) {
                        if(key == docId) {
                            retPosition = value;
                        }
                    });

                    return retPosition;
                };

                var getCalculateDocumentPosition = function(doc) {
                    var modifier = $scope.signConfigUser.typeConflict == 'SEGUENT_HORARIA' ? 1 : -1;
                    var retPosition = $scope.signConfigUser.position + modifier;

                    while(retPosition != $scope.signConfigUser.position) {
                        if(!_.contains(doc.positions, retPosition)) {
                            return retPosition;
                        }

                        retPosition += modifier;

                        if(retPosition == 7) {
                            retPosition = 1;
                        } else if(retPosition == 0) {
                            retPosition = 6;
                        }
                    }

                    return 0;
                }
                _.forEach(self.wizard.docsToSign, function(doc) {
                    var docToAdd = {
                        id: doc.id,
                        actionOrder: doc.actionOrder,
                        document: doc.document,
                        position: doc.position,
                        signActionType: doc.signActionType,
                        firstPage: doc.firstPage,
                        signState: 'pending',
                        addComments: ((doc.document && doc.document.id && self.wizard && self.wizard.steps && self.wizard.steps[1] && self.wizard.steps[1].annexaFormly && self.wizard.steps[1].annexaFormly.model[doc.document.id])?self.wizard.steps[1].annexaFormly.model[doc.document.id]:false)
                    };


                    switch (doc.conflict) {
                        case 'ask':
                            docToAdd.position = getAskDocumentPosition(docToAdd.document.id, self.wizard.steps[0].annexaFormly.model);
                            break;
                        case 'calculate':
                            docToAdd.position = getCalculateDocumentPosition(doc);
                            break;
                        case 'none':
                            docToAdd.position = $scope.signConfigUser.position;
                            break;
                    }
                    documentsToSign.push(docToAdd);
                });

                var wizardResult = {
                    serverCertificate: self.wizard.steps[2].annexaFormly.model.serverCertificate,
                    pinServerCertificate: self.wizard.steps[3].annexaFormly.model.pinServerCertificate,
                    localCertificate: !self.wizard.steps[4].disabled ? self.wizard.steps[4].annexaFormly.model.localCertificate : undefined
                };

                _.forEach(documentsToSign, function(value, key) {
                    var type = 'server';
                    var certificate = wizardResult.serverCertificate;
                    var pin = wizardResult.pinServerCertificate;

                    if(value.signActionType.code == apiSign.SIGNATURE_RECOGNIZED_CODE) {
                        type = 'local';
                        certificate = wizardResult.localCertificate;
                        pin = undefined;
                    }

                    documentsToSign[key].type = type;
                    documentsToSign[key].certificate = certificate;
                    documentsToSign[key].pin = pin;
                });

                $scope.docsToSign = documentsToSign;

                angular.element('#signWizardModal .modal-footer button').text($filter('translate')('global.literals.close'));
                angular.element('#signWizardModal .modal-footer button').addClass('disabled');

                var el = $compile('<annexa-sign-result documents-to-sign="docsToSign"></annexa-sign-result>')($scope);
                angular.element('#signWizardModal .modal-body').replaceWith(el);
            }
            
            $scope.finishStep0SignWizardCloudSignAndorra = function ($modal) {
            	$rootScope.loadingState(true);
            	
                var self = this;
                
                var wizardResult = {
                		nrt: self.wizard.steps[0].annexaFormly.model.nrt,
                		description: self.wizard.steps[0].annexaFormly.model.description
                };
                
                var documentsToSign = [];
                
                _.forEach(self.wizard.docsToSign, function(doc) {
                    var docToAdd = {
                        id: doc.id,
                        actionOrder: doc.actionOrder,
                        document: doc.document,
                        position: doc.position,
                        signActionType: doc.signActionType,
                        firstPage: doc.firstPage,
                        signState: 'pending',
                        position: $scope.signConfigUser.position
                    };
                  
                    documentsToSign.push(docToAdd);
                });
                
                _.forEach(documentsToSign, function(value, key) {
                    var type = 'cloudSignAndorra';
                    documentsToSign[key].type = type;
                });
                
                $scope.docsToSign = documentsToSign;
                
                //Datos del servidor
                var locationPrefix = $location.$$protocol + "://" + $location.$$host + ($location.$$port != 80 ? ':' + $location.$$port : '');

                DccumentsFactory.callCloudSignAndorra($scope.docsToSign[0].id, $scope.docsToSign[0].document.id, locationPrefix, wizardResult.nrt, wizardResult.description)
	                .then(function(data) {
	                	$rootScope.loadingState(true);
	                	
	                	//Guarda los datos necesarios para posteriormente finalizar la firma
	                	$scope.docsToSign[0].urlCloudSignAndorra = data.url;
	                	$scope.docsToSign[0].tempPathfileName = data.tempPathfileName;
	                	localStorage.setItem("docsToSignCloudSignAndorra", JSON.stringify($scope.docsToSign));
	                	
	                	var url = unescape($scope.docsToSign[0].urlCloudSignAndorra);	                	
	                	if (url == '') {
	                		self.alerts.push({ msg: $filter('translate')('global.errors.addQrError')});	                		
	                	} else {	                		
	                		//Llamada externa para firmar el documento
	                		$window.open(url, '_self');
	                	}
	                	
	                }).catch(function(error) {
	                	self.alerts.push({ msg: $filter('translate')('global.errors.addQrError')});
	                	$rootScope.loadingState(false);
	            });                                
            }
            
            $scope.finishSignWizardBIOSIGN = function ($modal) {
            	var self = this;
            	
                var documentsToSign = [];
                
                var getAskDocumentPosition = function(docId, asked) {
                    var retPosition = 0;

                    _.forOwn(asked, function(value, key) {
                        if(key == docId) {
                            retPosition = value;
                        }
                    });

                    return retPosition;
                };

                var getCalculateDocumentPosition = function(doc) {
                    var modifier = $scope.signConfigUser.typeConflict == 'SEGUENT_HORARIA' ? 1 : -1;
                    var retPosition = $scope.signConfigUser.position + modifier;

                    while(retPosition != $scope.signConfigUser.position) {
                        if(!_.contains(doc.positions, retPosition)) {
                            return retPosition;
                        }

                        retPosition += modifier;

                        if(retPosition == 7) {
                            retPosition = 1;
                        } else if(retPosition == 0) {
                            retPosition = 6;
                        }
                    }

                    return 0;
                }
                _.forEach(self.wizard.docsToSign, function(doc) {
                    var docToAdd = {
                        id: doc.id,
                        actionOrder: doc.actionOrder,
                        document: doc.document,
                        position: $scope.signConfigUser.position,
                        signActionType: doc.signActionType,
                        firstPage: doc.firstPage,
                        signState: 'pending',
                        type:'bioSign',
                        diviceSign: self.wizard.steps[1].annexaFormly.model.diviceSign.DeviceName,
                		nameSignedThird: self.wizard.steps[1].annexaFormly.model.nameSignedThird,
                		docSignedThird: self.wizard.steps[1].annexaFormly.model.docSignedThird
                    };


                    switch (doc.conflict) {
	                    case 'ask':
	                        docToAdd.position = getAskDocumentPosition(docToAdd.document.id, self.wizard.steps[0].annexaFormly.model);
	                        break;
	                    case 'calculate':
                            docToAdd.position = getCalculateDocumentPosition(doc);
                            break;
                        case 'none':
                            docToAdd.position = $scope.signConfigUser.position;
                            break;
                    }
                    documentsToSign.push(docToAdd);
                });
                $scope.docsToSign = documentsToSign;

                angular.element('#signWizardModal .modal-footer button').text($filter('translate')('global.literals.close'));
                angular.element('#signWizardModal .modal-footer button').addClass('disabled');

                var el = $compile('<annexa-sign-result documents-to-sign="docsToSign"></annexa-sign-result>')($scope);
                angular.element('#signWizardModal .modal-body').replaceWith(el);                
            }
            
            $scope.finishStep1SignWizardCloudSignAndorra = function ($modal) {
            	var self = this;
            	
            	$scope.tableQueued.reloadInternalData(callbackInstance1,true);
        		$state.go('annexa.sign.finished', {}, {reload: false});
        		HeaderService.changeState($state.current);
                   
        		self.close(); //cierra el modal
            }
            
            if ($stateParams.typeDash == 'isCloudSignUrlOK') {
            	var docsToSign = JSON.parse(localStorage.getItem("docsToSignCloudSignAndorra"));            	
            	var documentId = docsToSign[0].document.id;
            	var url = docsToSign[0].urlCloudSignAndorra;
            	
            	DccumentsFactory.callfinishCloudSignAndorra(documentId, url).then(function() {            	
	        		//Carga el modal con el segundo paso para la firma en la nube de Andorra
	            	var modal = angular.copy(SignModals.signWizardCloudSignAndorra);            	            	
	            	modal.wizard.modal = modal;
	                modal.wizard.steps[0].disabled = false;
	                modal.submitModal = $scope.finishStep1SignWizardCloudSignAndorra;
	                var idModal = 'signWizardModal';
	                var template = '<annexa-formly-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false" showdebuginfo="false" hidesubmitbutton="true" buttonlabel=""/>';
	                
	                modal.preClose = function() {
	                    $scope.tableQueued.reloadInternalData(callbackInstance1,true);
	                    $state.go('annexa.sign.finished', {}, {reload: false});
	                    HeaderService.changeState($state.current);
	                }
	                
	                ModalService.showModal({
	                    template: template,
	                    controller: 'AnnexaFormlyModalController',
	                    modalObj: modal
	                }).then(function (res) {
	                	modal.modal = res;
	                	modal.close = res.element.scope().close;
	                	res.element.modal();
	                    angular.element(".modal-title").focus();  
	                    
	                	var docsToSign = JSON.parse(localStorage.getItem("docsToSignCloudSignAndorra")); 
	                	$scope.docsToSign = docsToSign;
	                	localStorage.removeItem("docsToSignCloudSignAndorra");
	                  
	                	angular.element('#signWizardModal .modal-footer button').text($filter('translate')('global.literals.close'));
	                	angular.element('#signWizardModal .modal-footer button').addClass('disabled');
	
	                	var el = $compile('<annexa-sign-result documents-to-sign="docsToSign"></annexa-sign-result>')($scope);
	                 	angular.element('#signWizardModal .modal-body').replaceWith(el);
	                });
	                
            	}).catch(function(error) {
            		DialogsFactory.error($filter('translate')('global.errors.addQrError'));
            		$rootScope.loadingState(false);
            	});	                               
            } else if ($stateParams.typeDash == 'isCloudSignUrlError') {
            	//Carga el dialogo indicando error al realizar la firma en la nube de Andorra
            	DialogsFactory.error($filter('translate')('global.errors.errorCloudSignUrlError'));
            }

            $scope.getSelectedCount=function(){
                var ret=0;
                if($scope.tableQueued && $scope.tableQueued.list && $scope.tableQueued.list.length > 0){
                    ret = $scope.tableQueued.list.length;
                }
                return ret;
            }



           $scope.validate =function(row,iddoc){
                SignService.validateSign(row,iddoc).then(function(ret){
                    $scope.tablePending.reloadInternalData(false,true);
                    HeaderService.changeState($state.current);
                }).catch(function(error){
                	if(error && error.message && error.message == 'Error book state modify'){
                		DialogsFactory.error($filter('translate')('global.errors.errorCancelBookState'));
                	}else{
                		DialogsFactory.error($filter('translate')('global.errors.errorValidateSign')); 
                	}
                });
           };



            $scope.reject=function(row, iddoc){
                SignService.rejectSign(row, iddoc, $scope.alertModal.rejectedComment).then(function(ret){
                    if(ret){
                        $scope.tablePending.reloadInternalData(false,true);
                        HeaderService.changeState($state.current);
                    }
                }).catch(function(error){
                	if(error && error.message && error.message == 'Error book state modify'){
                		DialogsFactory.error($filter('translate')('global.errors.errorCancelBookState'));
                	}else{
                		DialogsFactory.error($filter('translate')('global.errors.errorCancelSign')); 
                	}
                });
             };

                $scope.addComments=function(id){
                    var content = {
                     id:id,
                     comments:$scope.delegatecomments['comment'+id]
                    };
                     SignService.updateObject(apiSign.getSignProcessActions,id,content).then(function (dataUpdate) {
                         if($scope.tablePending && $scope.tablePending.reloadInternalData){
                             $scope.tablePending.reloadInternalData(false,true);
                         }
                         if($scope.tableQueued && $scope.tableQueued.reloadInternalData){
                             $scope.tableQueued.reloadInternalData(false,true);
                         }
                         if($scope.tableFinished != null && $scope.tableFinished.reloadInternalData){
                             $scope.tableFinished.reloadInternalData(false,true);
                         }
                     }).catch(function () {
                     });
                };

                $scope.addCommentsToArray=function(id){
                    var rowPending = $scope.getRow(id,$scope.tablePending.list);
                    $scope.otherComments.length = 0;
                    if(rowPending){
                        $scope.delegatecomments['comment'+id] = rowPending.comments;
                        $scope.getOtherComments(rowPending);
                    }else{
                        var rowQueued = $scope.getRow(id,$scope.tableQueued.list);
                        if(rowQueued){
                            $scope.delegatecomments['comment'+id] = rowQueued.comments;
                            $scope.getOtherComments(rowQueued);
                        }else {
                            var rowFinished = $scope.getRow(id, $scope.tableFinished.list);
                            if (rowFinished) {
                                $scope.delegatecomments['comment'+id] = rowFinished.comments;
                                $scope.getOtherComments(rowFinished);
                            }else{
                                $scope.delegatecomments['comment'+id] = '';
                            }
                        }
                    }
                };

               $scope.getOtherComments = function(row){
                   if(row && row.signProcess && row.signProcess.signProcessActions && row.signProcess.signProcessActions.length > 0 ){
                       $scope.otherComments.length = 0;
                       if(row.signProcess.comments && row.signProcess.userSendedToSign && row.signProcess.userSendedToSign.identifier){
                    	   $scope.otherComments.push(row.signProcess.userSendedToSign.identifier+' -> '+row.signProcess.comments);
                       }
                       for(var i=0; i<row.signProcess.signProcessActions.length; i++) {
                           if(row.id != row.signProcess.signProcessActions[i].id && row.signProcess.signProcessActions[i].comments  && row.signProcess.signProcessActions[i].userSign && row.signProcess.signProcessActions[i].userSign.identifier){
                               $scope.otherComments.push(row.signProcess.signProcessActions[i].userSign.identifier+' -> '+row.signProcess.signProcessActions[i].comments);
                           }
                       }
                   }
               }

               $scope.clearComments=function(id){
                   var rowPending = $scope.getRow(id,$scope.tablePending.list);
                   if(rowPending){
                       rowPending.comments = '';
                       var content = {
                           id:id,
                           comments:''
                       };
                       SignService.updateObject(apiSign.getSignProcessActions,id,content).then(function (dataUpdate) {
                           if($scope.tablePending && $scope.tablePending.reloadInternalData){
                               $scope.tablePending.reloadInternalData(false,true);
                           }
                           if($scope.tableQueued && $scope.tableQueued.reloadInternalData){
                               $scope.tableQueued.reloadInternalData(callbackInstance1,true);
                           }
                           if($scope.tableFinished && $scope.tableFinished.reloadInternalData != null){
                               $scope.tableFinished.reloadInternalData(callbackInstance1,true);
                           }
                       }).catch(function () {
                       });
                   }
                   var rowQueued = $scope.getRow(id,$scope.tableQueued.list);
                   if(rowQueued){
                       rowQueued.comments = '';
                       if(!rowPending){
                           var content = {
                               id:id,
                               comments:''
                           };
                           SignService.updateObject(apiSign.getSignProcessActions,id,content).then(function (dataUpdate) {
                               if($scope.tablePending && $scope.tableQueued.reloadInternalData){
                                   $scope.tablePending.reloadInternalData(false,true);
                               }
                               if($scope.tableQueued && $scope.tableQueued.reloadInternalData){
                                   $scope.tableQueued.reloadInternalData(callbackInstance1,true);
                               }
                               if($scope.tableFinished && $scope.tableFinished.reloadInternalData){
                                   $scope.tableFinished.reloadInternalData(callbackInstance1,true);
                               }
                           }).catch(function () {
                           });
                       }
                   }
                   var rowFinished = $scope.getRow(id,$scope.tableFinished.list);
                   if(rowFinished){
                       rowFinished.comments = '';
                       if(!rowPending && !rowQueued){
                           var content = {
                               id:id,
                               comments:''
                           };
                           SignService.updateObject(apiSign.getSignProcessActions,id,content).then(function (dataUpdate) {
                               if($scope.tablePending && $scope.tablePending.reloadInternalData){
                                   $scope.tablePending.reloadInternalData(false,true);
                               }
                               if($scope.tableQueued && $scope.tableQueued.reloadInternalData){
                                   $scope.tableQueued.reloadInternalData(callbackInstance1,true);
                               }
                               if($scope.tableFinished != null && $scope.tableFinished.reloadInternalData){
                                   $scope.tableFinished.reloadInternalData(callbackInstance1,true);
                               }
                           }).catch(function () {
                           });
                       }
                   }
               };

               $scope.validateAlert = function(iddoc){
                   if($scope.alertModal.action == 'reject'){
                       $scope.reject($scope.alertModal.signing,iddoc);
                   }else if($scope.alertModal.action == 'validar'){
                       $scope.validate($scope.alertModal.signing,iddoc);
                   }
               };

            $scope.viewDocument = function(id) {
                $scope.showLoadingdiv = true;
                DccumentsFactory.getDocContent(id)
                    .then(function (data) {
                        $scope.showLoadingdiv = false;
                        window.open(data, '_blank');
                    }).catch(function (error) {
                    $scope.showLoadingdiv = false;
                });
            }

            $scope.focusSignModal = function(){
                $timeout(function () {
                    angular.element('#firstpage').focus();
                })
            }
        }]);
