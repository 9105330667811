/**
 * Created by osirvent on 18/11/2015.
 */

angular
    .module('annexaApp')
    .constant('apiSign', {
        getSignProcessActions: './api/signprocessactions',
        countSignProcessActionsByFilter: './api/signprocessactions/countByfilter',
        getSignActionTypeSelected: './api/signprocessactions/getSignActionTypeSelected',
        getDelegatedDelegations:'./api/delegationUsers/findByDelegatedUserIdAndStartDelegationDateBeforeAndEndDelegationDateAfter',
        getDelegatorDelegations:'./api/delegationUsers/findByDelegatorUserIdAndEndDelegationDateAfter',
        getValidsDocuments:'./api/signprocessactions/validsDocuments',
        insertDelegationUsers: './api/delegationUsers/insertDelegationUser',
        deleteDelegationUsers: './api/delegationUsers/deleteDelegationUser',
        getSignConfigUserById: './api/signConfigUser/findFirstByUserId',
        getSignConfigUser: './api/signConfigUser/insertOrUpdateConfig',
        getUserCertificates: './api/certificates/getUserCertificates',
        getCountDocsToSignWithLocalSignature: './api/sign/getCountDocsToSignWithLocalSignature',
        executeSign: './api/sign/do',
        SIGNSTATE_REJECTED: {code:'R', id:"REJECTED"},
        VALIDATE_CODE: 'VALIDATION',
        SIGNATURE_CODE: 'SIGN',
        SIGNATURE_RECOGNIZED_CODE: 'LOCALSIGN',
        SIGNATURE_CLOUD_ANDORRA_CODE: 'CLOUDSIGN_ANDORRA',
        SIGNATURE_BIO_SIGN_CODE: 'BIOSIGN'
   })
   .constant('SignModals', {
       sendToSign: {
           title: 'global.literals.signProcess',
           size: 'modal-lg',
           icon: 'fa fa-pencil-square-o',
           data: undefined,
           content: [
               '<annexa-send-to-sign document-ids="modal.data.documentIds" documents="modal.data.documents" result="modal.data.result"></annexa-send-to-sign>'
           ],
           alerts: []
       },
       signWizard: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
                   {
                       title: 'global.sign.configModal.conflictModal.title',
                       key: 'conflictSignPosition',
                       disabled: true,
                       disableNext: function (model) {
                           var allSelected = true;

                           _.forOwn(model, function (value, key) {
                               if(value == -1) {
                                   allSelected = false;
                               }
                           });

                           return !allSelected;
                       },
                       exitValidation: function (model) {
                           var allSelected = true;

                           _.forOwn(model, function (value, key) {
                               if(value == -1) {
                                   allSelected = false;
                               }
                           });

                           return allSelected;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'signConflict',
                                   type: 'annexaSignConflict',
                                   templateOptions: {
                                       required: true,
                                       documents: [],
                                       textConflict: 'global.sign.configModal.conflictModal.description'
                                   },
                                   controller: ['$scope', function($scope) {
                                       $scope.radioClick = function($event, document, position) {
                                           if($scope.model && $scope.model[document.document.id]) {
                                               $scope.model[document.document.id] = position;
                                               angular.element('.' + document.document.id + ' i').removeClass('fa-dot-circle-o');
                                               angular.element('.' + document.document.id + ' i').addClass('fa-circle-o');
                                               angular.element($event.target).removeClass('fa-circle-o');
                                               angular.element($event.target).addClass('fa-dot-circle-o');
                                           }
                                       }
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.signComments',
                       key: 'saveCommentsDocument',
                       disabled: true,
                       disableNext: function (model) {
                           return false;
                       },
                       exitValidation: function (model) {
                           return true;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'saveCommentsToDocument',
                                   type: 'annexaSaveCommentsToDocument',
                                   templateOptions: {
                                       required: true,
                                       documents: [],
                                       textSignComment: 'global.sign.queued.signCommentsDescription'
                                   }
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.chooseCertificate',
                       key: 'serverCertificate',
                       disabled: true,
                       disableNext: function(model, wizard) {
                           var disabled = true;
                           if(model && model.serverCertificate && model.serverCertificate.id){
                               disabled = false;
                           }
                           return disabled;
                    
                       /*disableNext: function () {
                           var elements = angular.element("#pinServerCertificate");
                           var exist = false;
                           _.forEach(elements, function(element){
                               if(element && element.value){
                                   exist = true;
                               }
                           })
                           return exist;
                           //return this.annexaFormly.form.$invalid;*/
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'serverCertificate',
                                   type: 'annexaCertificates',
                                   templateOptions: {
                                       required: true,
                                       certificates: []
                                   },
                                   controller: ['SignService', '$rootScope', '$scope', function (SignService, $rootScope, $scope) {
                                       $scope.loading = true;
                                       SignService.getUserCertificates($rootScope.LoggedUser.id)
                                           .then(function(data) {
                                               if(data) {
                                                   var decodedData = JSOG.decode(data);
                                                   _.forEach(decodedData, function (certificate) {
                                                       certificate.isDelegated = function() {
                                                           return this.userOwner.id != $rootScope.LoggedUser.id;
                                                       };
                                                   });

                                                   $scope.to.certificates = decodedData;

                                                   $scope.loading = false;
                                               }
                                           })
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.literals.pinTitle',
                       key: 'serverCertificatePIN',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'pinServerCertificate',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'password',
                                       label: 'global.literals.PIN',
                                       required: true,
                                       focus: false
                                   }

                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.chooseLocalCertificate',
                       key: 'localCertificate',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'localCertificate',
                                   type: 'annexaCertificates',
                                   templateOptions: {
                                       required: true,
                                       certificates: []
                                   },
                                   controller: ['SignService', '$rootScope', '$scope', function (SignService, $rootScope, $scope) {
                                       $scope.loading = false;
                                   }]
                               }
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       signWizardCloudSignAndorra: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
                   {
                       title: 'global.sign.literals.enterNRT',
                       key: 'enterNRT',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'nrt',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.NRT',
                                       required: true,
                                       focus: false
                                   }
                               },
                               {
                                   key: 'description',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.description',
                                       required: true,
                                       focus: false
                                   }
                               }                               
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       signWizardBIOSIGN: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
            	   {
                           title: 'global.sign.configModal.conflictModal.title',
                           key: 'conflictSignPosition',
                           disabled: true,
                           disableNext: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return !allSelected;
                           },
                           exitValidation: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return allSelected;
                           },
                           annexaFormly: {
                               fields: [
                                   {
                                       key: 'signConflict',
                                       type: 'annexaSignConflict',
                                       templateOptions: {
                                           required: true,
                                           documents: [],
                                           textConflict: 'global.sign.configModal.conflictModal.description'
                                       },
                                       controller: ['$scope', function($scope) {
                                           $scope.radioClick = function($event, document, position) {
                                               if($scope.model && $scope.model[document.document.id]) {
                                                   $scope.model[document.document.id] = position;
                                                   angular.element('.' + document.document.id + ' i').removeClass('fa-dot-circle-o');
                                                   angular.element('.' + document.document.id + ' i').addClass('fa-circle-o');
                                                   angular.element($event.target).removeClass('fa-circle-o');
                                                   angular.element($event.target).addClass('fa-dot-circle-o');
                                               }
                                           }
                                       }]
                                   }
                               ]
                           }
                   },
                   {
                       title: 'global.sign.literals.enterBioSignProperties',
                       key: 'enterNRT',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'diviceSign',
                                   type: 'annexaSelectRow',
                                   className: '',
                                   templateOptions: {
                                       optionsAttr: 'bs-options',
                                       ngOptions: 'option in to.options | filter: $select.search',
                                       label: 'global.literals.diviceSign',
                                       valueProp: 'DeviceName',
                                       labelProp: 'DeviceDescription',
                                       placeholder: '',
                                       options: [],
                                       required: true
                                   },
                                   controller: ['$scope', '$filter', 'Language', '$rootScope', 'SignService', function($scope, $filter, Language, $rootScope, SignService) {
                                	   $scope.to.options = [];
                                	   if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.bioSignConfiguration && $rootScope.app.configuration.bioSignConfiguration.baseUrl && $rootScope.app.configuration.bioSignConfiguration.auth){
                                		   SignService.getBioSignDevices($rootScope.app.configuration.bioSignConfiguration.baseUrl, $rootScope.app.configuration.bioSignConfiguration.auth).then(function(data){
                                			   if(data){
                                				   Array.prototype.push.apply($scope.to.options,data);
                                			   }
                                		   }).catch(function(error){
                                			   console.log(error);
                                		   });
                                	   }
                                   }],
                                   data: {
                                       row: true,
                                       informed: true,
                                       colClass: ' col-sm-12',
                                       labelClass: 'label-strong'
                                   }
                               },
                               {
                                   key: 'nameSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.nameSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               },
                               {
                                   key: 'docSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.docSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               }                               
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       }
   });
