/**
 * Created by tmedrano on 11/12/2015.
 */
angular
    .module('annexaApp')
    .directive('circleicon', ['$parse',function($parse) {


        var directive = {
            scope: true,
            replace: true,
            restrict: 'E',
            transclude: true,
            template: '<span class="circle" ng-class="[iconsize,bgcolor]" title="{{titleicon}}"><span  ng-class="[textsize,textcolor]" ng-transclude></span></span>',
            link: function (scope, element, attrs) {
                var defaultbgcolor="primary";
                var defaulttextcolor="white";
                var defaultsize="md";


                //funció que retorna el valor de l'atribut passat si existeix a l'scope.
                // Si no existeix, retorna el propi nom passat
                var parseIfExists = function (thing,scope) {
                    //console.log(scope.tipsign);
                    if(!scope) return false;
                    if(!thing) return false;

                    var things=thing.split(".");
                    //console.log("Busco");
                    //console.log(things);
                    var ret=scope;
                    for(var i=0;i<things.length;i++){
                        if(typeof ret[things[i]] === "undefined"){
                            return thing;
                        }else{
                            ret=ret[things[i]];
                        }
                    }
                   // console.log("found!");
                    ret=$parse(thing)(scope);
                    //console.log(ret);
                    return ret;


                };

                scope.bgcolor=(!(typeof  attrs.bgcolor === "undefined"))?parseIfExists(attrs.bgcolor,scope):defaultbgcolor;
                scope.textcolor=(!(typeof  attrs.textcolor === "undefined"))?parseIfExists(attrs.textcolor,scope):defaulttextcolor;
                scope.iconsize=(!(typeof  attrs.size === "undefined"))?parseIfExists(attrs.size,scope):defaultsize;
                scope.textcolor="text-"+scope.textcolor;
                scope.titleicon=(!(typeof attrs.titleicon === "undefined"))?parseIfExists(attrs.titleicon,scope):'';

                var sizes={xs:16,sm:24,md:32,xl:38,lg:48};


                if(typeof  sizes[scope.iconsize] === "undefined") scope.iconsize=defaultsize;


                scope.iconsize="w-"+sizes[scope.iconsize];
                scope.textsize="text-"+scope.iconsize;
            }
        };
        return directive;
    }]);

